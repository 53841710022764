import Lottie from "lottie-react";
import { motion } from "framer-motion";
import roadmap from "../../assets/images/roadmap/roadmap.svg";
import smallRoadmap from "../../assets/images/roadmap/sm-roadmap.svg";
import star from "../../assets/animations/star.json";
import { useEffect, useState } from "react";
const RoadMap = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerVariants = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <motion.div
      id="roadmap"
      className="relative  text-center  mt-16 sm:mt-56 flex flex-col items-center justify-center "
    >
      <motion.div
        className="flex flex-col items-center mb-10 sm:mb-20 justify-center mx-7 sm:mx-20"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
      >
        <p
          className="font-dk-scrawny text-2xl sm:text-5xl text-white"
          style={{ transform: "rotate(8.2deg)" }}
        >
          Roadmap: The Journey of HOAXTRUMP
        </p>

        <motion.div className="relative mt-[5rem]">
          <img src={isMobile ? smallRoadmap : roadmap} alt="Roadmap" />

          <motion.div
            className="top-right-lottie absolute sm:-top-[9rem] sm:-left-[2rem] left-[7rem] xl:left-4 -top-24  xl:-top-[10rem]  lg:-top-[9rem] lg:left-5 md:-top-[9rem] md:left-2 w-32 sm:w-[12rem]"
            style={{ transform: "rotate(45deg)" }}
            variants={itemVariants}
          >
            <Lottie
              animationData={star}
              style={{ transform: "rotate(45deg)" }}
            />
          </motion.div>
          <motion.div
            className="bottom-lottie absolute left-[2rem] md:bottom-[7rem]  md:left-[43%] right-10 sm:bottom-[4rem] sm:right-1/2 top-[4.6rem] lg:bottom-[12.5rem] sm:left-[25%] w-28 sm:w-[8rem] flex items-end "
            variants={itemVariants}
          >
            <Lottie
              animationData={star}
              style={{ transform: "rotate(-19.22deg)" }}
            />
          </motion.div>
          <motion.div
            className="top-left-lottie absolute right-[3rem] xl:right-[10rem] bottom-[5rem] xl:-top-[4.5rem] md:-top-[4rem] md:right-[1.5rem] sm:-top-[7rem] lg:right-[6rem] lg:-top-[4.5rem] sm:right-[1rem] w-28 sm:w-[10rem]"
            variants={itemVariants}
          >
            <Lottie
              animationData={star}
              style={{ transform: "rotate(30deg)" }}
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default RoadMap;
