import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import themeReducer from "./reducer/theme.slice";
import userReducer from "./reducer/users.slice";
import spinReducer from "./reducer/spinner.slice";
import walletReducer from "./reducer/wallet.slice";

const themePersistConfig = {
  key: "theme",
  version: 1,
  storage,
};
const walletPersistConfig = {
  key: "wallet",
  version: 1,
  storage,
};
const persistedThemeReducer = persistReducer(themePersistConfig, themeReducer);
const persistedWalletReducer = persistReducer(
  walletPersistConfig,
  walletReducer
);
const rootReducer = combineReducers({
  theme: persistedThemeReducer,
  wallet: persistedWalletReducer,
  users: userReducer,
  spinner: spinReducer,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
