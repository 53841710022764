import { motion } from "framer-motion";
import meme1 from "../../assets/images/meme/cool-trump.svg";
import heroImg from "../../assets/images/hero.png";
import discord from "../../assets/images/Community/Discord.svg";
import instagram from "../../assets/images/Community/Instagram.svg";
import telegram from "../../assets/images/Community/Telegram.svg";
import x from "../../assets/images/Community/X.svg";
import deleteImg from "../../assets/images/delete.svg";
import flagImg from "../../assets/images/flag.svg";
import upArrow from "../../assets/images/Community/up-arrow.svg";
import { useEffect, useState } from "react";

const Community = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const coolest = "Coolest".split("");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const staggerContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // delay between child animations for main container
      },
    },
  };

  // Variants for the individual items (images)
  const itemVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  // Variants for the coolest text with a reduced stagger
  const coolestTextContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05, // Reduced delay for coolest letters
      },
    },
  };

  const coolestTextItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <div className="relative">
      <div className="relative h-[8rem] sm:h-[30rem] lg:h-screen mt-40 mx-9 ">
        <div>
          <div className=" absolute left-0 -top-40 sm:-top-[12rem] max-w-[8rem] sm:max-w-[11.7rem] md:max-w-[17.7rem] xl:max-w-[21.7rem] lg:-top-[12rem] md:-top-[11.5rem] overflow-x-hidden">
            <img src={flagImg} alt="Flag" />
          </div>
          <motion.div
            whileHover={{ scale: 1.1, rotate: -10 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className=" absolute z-10 left-[1rem] -top-20  md:top-[2rem] max-w-36 sm:max-w-64 md:max-w-[16rem] xl:max-w-[20rem] xl:top-[2.9rem] lg:-top-[1rem] "
          >
            <img src={meme1} alt="Meme 1" />
          </motion.div>
          <div
            className=" absolute  max-w-xl xl:max-w-5xl text-center z-10 -right-5 sm:-right-9 -top-9 sm:top-20 text-white"
            style={{ transform: "rotate(-10.32deg)" }}
          >
            <motion.p
              variants={staggerContainer}
              initial="hidden"
              whileInView="show" // Animation starts when the component is in view
              viewport={{ once: false, amount: 0.5 }}
              className="font-dk-scrawny text-2xl border-none w-[14rem] sm:w-full sm:text-5xl xl:text-8xl"
            >
              Join the{" "}
              <span className="relative">
                best{" "}
                <motion.img
                  variants={itemVariants}
                  className="absolute top-1/3 right-0"
                  src={deleteImg}
                  alt="Delete"
                />
                <motion.img
                  variants={itemVariants}
                  className="absolute flex justify-center -top-8 sm:-top-[5rem] left-1/3 h-6 sm:h-24"
                  src={upArrow}
                  alt="Up-Arrow"
                />
                {/* Coolest Text Animation */}
                <motion.p
                  variants={coolestTextContainer}
                  className="absolute -top-16 sm:-top-40 left-0"
                  style={{ transform: "rotate(27deg)" }}
                >
                  {coolest.map((coolestText, key) => (
                    <motion.span key={key} variants={coolestTextItem}>
                      {coolestText}
                    </motion.span>
                  ))}
                </motion.p>
              </span>
              meme Community
            </motion.p>
          </div>
          <div className="social-media flex absolute z-10 left-0 bottom-0">
            {/* <motion.img
              whileHover={{ scale: 1.1, rotate: 11 }}
              whileTap={{ scale: 0.9 }}
              animate={{ rotate: -11 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className=" mb-10 sm:mb-14 max-w-[2.9rem]  sm:max-w-[6rem] md:max-w-[8.5rem] lg:max-w-full"
              style={{ transform: "rotate(-10.46deg)" }}
              src={discord}
              alt="Discord"
            /> */}
            <a
              href="https://x.com/hoaxtrumphxt"
              target="_blank" // Opens the link in a new tab
              rel="noopener noreferrer" // Security reasons
            >
              <motion.img
                whileHover={{ scale: 1.1, rotate: -4 }}
                whileTap={{ scale: 0.9 }}
                animate={{ rotate: 4.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="max-w-[2.9rem] sm:max-w-[6rem] lg:max-w-full md:max-w-[8.5rem]"
                style={{ transform: "rotate(4.12deg)" }}
                src={x}
                alt="X"
              />
            </a>
            {/* <motion.img
              whileHover={{ scale: 1.1, rotate: 11 }}
              whileTap={{ scale: 0.9 }}
              animate={{ rotate: -11 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="pb-10 sm:pb-16 max-w-[2.9rem] sm:max-w-[6rem] lg:max-w-full md:max-w-[8.5rem]"
              style={{ transform: "rotate(-11.17deg)" }}
              src={telegram}
              alt="Telegram"
            /> */}
            {/* <motion.img
              whileHover={{ scale: 1.1, rotate: -9 }}
              whileTap={{ scale: 0.9 }}
              animate={{ rotate: 9 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="max-w-[2.9rem] sm:max-w-[6rem] lg:max-w-full md:max-w-[8.5rem]"
              style={{ transform: "rotate(9.08deg)" }}
              src={instagram}
              alt="Discord"
            /> */}
          </div>
          <div className=" absolute z-10 right-0 bottom-0  max-w-[6.5rem] sm:max-w-[10.5rem] lg:max-w-[15rem] xl:max-w-[23rem] md:bottom-20 rotate-12 lg:bottom-0 sm:bottom-10 md:max-w-[14rem]">
            <img className="object-contain" src={heroImg} alt="heroImg" />
          </div>
        </div>
      </div>
      <div
        className=" absolute -bottom-16 sm:-bottom-48 w-full h-32 sm:min-h-screen 2xl:-bottom-[15rem]"
        style={{
          // backgroundColor: "#ffff",
          backgroundImage: isMobile
            ? `url('data:image/svg+xml,<svg viewBox="0 0 375 220" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_934_390)"><g filter="url(%23filter0_d_934_390)"><path fill-rule="evenodd" clip-rule="evenodd" d="M129.383 -2136.08C59.0231 -2222.07 -3.27274 -2138.82 -3.27274 -2138.82C-3.27275 -2099.92 -2.96387 29.5056 -2.96387 189.922C212.661 222.474 124.119 138.621 385.238 209.454L385.239 -2088.37C385.239 -2088.37 350.978 -1999.97 317.713 -2074.47C260.366 -2202.87 234.96 -2007.04 129.383 -2136.08Z" fill="%23475C3A"/></g></g><defs><filter id="filter0_d_934_390" x="-7.43937" y="-2183.87" width="397.915" height="2395.41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="-2.08333"/><feGaussianBlur stdDeviation="2.08333"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_934_390"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_934_390" result="shape"/></filter><clipPath id="clip0_934_390"><rect width="375" height="220" fill="white"/></clipPath></defs></svg>')`
            : `url('data:image/svg+xml,<svg viewBox="0 0 1440 519" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_776_88)"><g filter="url(%23filter0_d_776_88)"><path fill-rule="evenodd" clip-rule="evenodd" d="M496.833 -4217.91C226.65 -4548.12 -12.5658 -4228.44 -12.5658 -4228.44C-12.5658 -4079.04 -11.3787 -172.047 -11.3788 443.953C816.621 568.953 476.624 246.945 1479.32 518.944L1479.32 -4034.69C1479.32 -4034.69 1347.76 -3695.26 1220.02 -3981.33C999.806 -4474.38 902.25 -3722.39 496.833 -4217.91Z" fill="%23475C3A"/></g></g><defs><filter id="filter0_d_776_88" x="-28.5659" y="-4401.44" width="1527.99" height="4928.38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="-8"/><feGaussianBlur stdDeviation="8"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_776_88"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_776_88" result="shape"/></filter><clipPath id="clip0_776_88"><rect width="1440" height="519" fill="white"/></clipPath></defs></svg>')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
    </div>
  );
};

export default Community;
