import React from "react";
import Accordion from "../Accordion/Accordion";

const FAQ = () => {
  return (
    <div
      id="faqs"
      className="pt-[10rem] sm:pt-[20rem] pb-24 px-10 sm:px-40 text-center font-Lato text-black bg-[#F1FFE6]"
    >
      <p className="title text-sm sm:text-4xl mb-4 sm:mb-10 font-semibold">
        FAQs: Your Hoaxtrump Questions Answered
      </p>
      <Accordion />
    </div>
  );
};

export default FAQ;
