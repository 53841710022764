import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import maskVector from "../../assets/images/mask-layer.svg";
import mobileMaskVector from "../../assets/images/mobile-mask-layer.svg";
import heroWalpaper from "../../assets/gif/hero-wallpaper.gif";
import meme1 from "../../assets/images/meme/meme-1.svg";
import meme2 from "../../assets/images/meme/meme-2.svg";
import meme3 from "../../assets/images/meme/meme-3.svg";
import meme4 from "../../assets/images/meme/meme-4.svg";
import joke1 from "../../assets/images/meme/joke-1.svg";
import joke2 from "../../assets/images/meme/joke-2.svg";
import joke3 from "../../assets/images/meme/joke-3.svg";
import joke4 from "../../assets/images/meme/joke-4.svg";

function Meme() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const parentVariants = {
    animate: {
      transition: {
        staggerChildren: 0.5, // Delay between each child
      },
    },
  };
  const childVariants = {
    animate: {
      x: [0, -5, 0, 5, 0],
      y: [0, 5, 0, -5, 0],
    },
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <motion.div
        variants={parentVariants}
        initial="hidden"
        animate="animate"
        whileInView="animate"
        viewport={{ once: false, amount: 0.5 }}
        className="  h-[58rem] sm:h-[30.75rem] md:h-[40rem] lg:h-[56rem]   2xl:h-[75rem] relative overflow-x-hidden overflow-hidden"
        style={{
          backgroundColor: "#fff", // White background for the masked area
          maskImage: isMobile
            ? `url(${mobileMaskVector})`
            : `url(${maskVector})`,
          WebkitMaskImage: isMobile
            ? `url(${mobileMaskVector})`
            : `url(${maskVector})`,
          maskSize: "cover",
          WebkitMaskSize: "cover",
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${heroWalpaper})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            opacity: 0.5,
          }}
        />
        <div className=" relative">
          <img
            className="absolute left-0 top-28 sm:top-[3.4rem] sm:left-11  sm:w-[22.29%] w-44 2xl:top-[10rem] lg:top-[8rem] md:top-[6rem]"
            src={meme1}
            alt="Meme 1"
          />
          <motion.img
            className="absolute left-36 top-[5.5rem] w-44 sm:left-48 sm:top-[2rem] sm:w-[22.18%] 2xl:left-[22vw] 2xl:top-[6rem] lg:left-[23%] lg:top-[5rem] md:left-[24%] md:top-[3.5rem]"
            src={joke1}
            alt="Meme Text 1"
            variants={childVariants}
          />
        </div>
        <div className="relative">
          <img
            className=" absolute right-0 top-80  sm:top-[6rem] sm:w-[19.4%] w-36 2xl:top-[14rem] lg:top-[13rem] sm:right-0 md:top-[10rem]"
            src={meme2}
            alt="Meme 2"
          />

          <motion.img
            className="absolute right-36 lg:right-[16rem] xl:right-72 top-[17rem] sm:top-[3rem] sm:right-[9rem] 2xl:right-[19%] 2xl:top-[8rem] sm:w-[25.78%] w-48 lg:top-[7.1rem] md:top-[6rem] md:right-[12rem]"
            src={joke2}
            alt="Meme Text 2"
            variants={childVariants}
          />
        </div>

        <img
          className="absolute -left-12 bottom-48  sm:left-20 sm:bottom-0 sm:w-[22.6%] 2xl:bottom-0 2xl:left-[10rem] w-52"
          src={meme3}
          alt="Meme 3"
        />

        <motion.img
          className=" absolute left-[8rem] bottom-[20rem] sm:left-[32.8%] sm:bottom-[7rem] sm:w-[17.65%] 2xl:left-[29%] 2xl:bottom-[19rem] w-36 lg:left-[25%] md:bottom-[25%] md:left-[28%]"
          src={joke3}
          alt="Meme Text 3"
          variants={childVariants}
        />

        <img
          className="absolute -right-12 -rotate-[15.26deg] sm:rotate-0  bottom-16 sm:right-20 sm:bottom-0 sm:w-[24.06%] 2xl:right-[10rem] w-48"
          src={meme4}
          alt="Meme 4"
        />

        <motion.img
          className="sm:w-[18.28%] absolute right-[6rem] -rotate-[15.26deg] sm:rotate-0 bottom-[9rem] sm:bottom-[6.5rem] sm:right-[28.8%] w-36 2xl:right-[26%] 2xl:bottom-[17.5rem] md:right-[23%]  md:bottom-[22%]"
          src={joke4}
          alt="Meme Text 4"
          variants={childVariants}
        />
      </motion.div>
    </div>
  );
}
export default Meme;
