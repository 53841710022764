import { useState } from "react";
import { motion } from "framer-motion";
import star from "../../assets/animations/star.json";
import buyHxt from "../../assets/images/swap/buyHxt.svg";
import arrow from "../../assets/images/swap/arrow.svg";
import buyBtn from "../../assets/images/buy-button.svg";
import pacakelogo from "../../assets/logo/pancakeswap-cake-logo.svg";
import Lottie from "lottie-react";
import "./Swap.css";

// import { SwapWidget } from "@uniswap/widgets";
// import "@uniswap/widgets/fonts.css";
// import { ethers } from "ethers";
// import detectEthereumProvider from "@metamask/detect-provider";
// import { useConnectWallet } from "@web3-onboard/react";
// import { ethers } from "ethers";

const NewSwap = () => {
  // const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  // const [provider, setProvider] = useState();
  // console.log("jfj:", provider);

  // const API_KEY = "d026233fb95349bbac858de906267e48";
  // const jsonRPCEndpoint = `https://mainnet.infura.io/v3/${API_KEY}`;
  // const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRPCEndpoint);
  // We recommend you pass your own JSON-RPC endpoints.
  // const jsonRpcUrlMap = {
  //   1: ["https://mainnet.infura.io/v3/d026233fb95349bbac858de906267e48"],
  //   3: ["https://ropsten.infura.io/v3/d026233fb95349bbac858de906267e48"],
  // };
  // const [account, setAccount] = useState({
  //   address: "",
  //   provider: jsonRpcProvider,
  // });
  const theme = {
    // container: '#D9ECD9',
    // interactive: '#CBD6BA',
    // accent: '#FAB317',
  };
  // const tokenList =
  //   "https://hoaxtrump-static.s3.us-east-1.amazonaws.com/tokenList.json";
  // const HXT = "0x6Fe4239f396fb169cd38d1fa8cDc174CF9824D5a";
  // const defaultInputAdd = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

  // const connectWallet = () => {
  //   connect();
  // };

  // async function connectWallet() {
  //   try {
  //     const ethereumProvider = await detectEthereumProvider();
  //     if (ethereumProvider && ethereumProvider.request) {
  //       await ethereumProvider.request({ method: "eth_requestAccounts" });
  //       const provider = new ethers.providers.Web3Provider(ethereumProvider);
  //       const signer = provider.getSigner();
  //       const address = await signer.getAddress();
  //       setAccount({
  //         address: address,
  //         provider: provider,
  //       });
  //     } else {
  //       console.error("Please install MetaMask!");
  //     }
  //   } catch (e) {
  //     console.log("Error:", e);
  //   }
  // }

  return (
    <div
      className="relative z-0 flex flex-col sm:flex-row gap-2 mx-auto sm:justify-between sm:mx-auto max-w-[83.33%] items-start mt-10 sm:my-20 font-Montserrat text-black"
      id="swap"
    >
      <div
        className="top-leftLottie w-[4rem] sm:w-[8rem] -left-12 top-[40%]"
        style={{
          transform: "rotate(-23.692deg)",
        }}
      >
        <Lottie animationData={star} loop={true} className="p-0" />
      </div>
      <div
        className="absolute hidden -right-[2.5rem] bottom-[4rem]   w-[7rem]  sm:block"
        style={{ transform: "rotate(-23.69deg)" }}
      >
        <Lottie animationData={star} loop={true} />
      </div>

      <div className="flex flex-col items-center content-center gap-1 sm:gap-4 rounded-2xl px-1.5 py-3 sm:p-3 relative md:ms-12rem] sm:mx-0 sm:ms-[2rem] lg:ms-[2rem]">
        <div className="left-box w-full  rounded-2xl relative">
          <div className="flex flex-col items-center gap-[0.6rem] sm:gap-6 mx-[0.65rem] sm:mx-5 my-[0.65rem] sm:my-[1.8rem] ">
            <img
              src={buyHxt}
              style={{ transform: "rotate(11.98deg)" }}
              alt="Buy hxt"
            />
            <img
              className=" hidden sm:block absolute right-0 -top-11"
              src={arrow}
              alt="Arrow of Buy hxt"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 w-[300px] sm:min-w-[43vw] items-center justify-center mx-auto content-center gap-[0.85rem] rounded-2xl p-[0.4rem] sm:p-3 sm:mx-0 sm:me-[2rem]">
        {/* <motion.a
          className="w-full h-[4rem] sm:h-[10rem] flex justify-between"
          href="https://pancakeswap.finance/?inputCurrency=BNB&outputCurrency=0x6Fe4239f396fb169cd38d1fa8cDc174CF9824D5a"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            textDecoration: "none",
            overflow: "hidden",
          }}
          initial="rest"
          whileHover="hover"
          whileTap={{ scale: 0.8 }}
          animate="rest"
          variants={{
            rest: { "--before-width": "0%", scale: 1 },
            hover: { "--before-width": "100%", scale: 1.05 },
          }}
        >
          <motion.div
            className="flex gap-2 w-full h-full px-3 py-2 items-center content-center justify-center text-white bg-[#4D7332] rounded-[2rem]"
            style={{
              position: "relative",
              overflow: "hidden",
              zIndex: 1,
            }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 0.5,
            }}
          >
            <img className="w-8" src={pacakelogo} alt="PacakeSwap logo" />
            <span className="text-base sm:text-2xl lg:text-3xl 2xl:text-4xl">
              Buy on PancakeSwap
            </span>
            <motion.div
              className="button-overlay"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "var(--before-width)",
                height: "100%",
                backgroundColor: "#2E451E",
                zIndex: -1,
              }}
              variants={{
                rest: { width: "0%" },
                hover: { width: "100%" },
              }}
              transition={{
                duration: 0.3,
                ease: "easeInOut",
              }}
            />
          </motion.div>
        </motion.a> */}
        <motion.a
          className="relative w-full h-[7rem] sm:h-[10rem] text-base sm:text-2xl lg:text-3xl 2xl:text-4xl rounded-tr-[65px] rounded-bl-[65px] border-[1px] border-[#4D7332] bg-[#4D7332] text-white shadow-[0px_2px_4px_#4D7332] sm:shadow-lg"
          whileTap={{ scale: 0.85, y: 4 }}
          whileHover={{ scale: 0.95, y: -3 }}
          href="https://pancakeswap.finance/?inputCurrency=BNB&outputCurrency=0x6Fe4239f396fb169cd38d1fa8cDc174CF9824D5a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="w-full h-full">
            <p>Buy/Sell</p>
            <p>on</p>
            <div className="flex items-center justify-center gap-2">
              <img className="w-8" src={pacakelogo} alt="PancakeSwap logo" />
              <span>PancakeSwap</span>
            </div>
            {/* Inner shadow for 3D effect */}
            <div className="absolute top-1 left-1 w-[calc(100%-0.1rem)] h-[calc(100%+0.4rem)] bg-[#4D7332] rounded-tr-[60px] rounded-bl-[60px] -z-10 transition-transform duration-300"></div>
          </button>
        </motion.a>

        {/* <button
          className=" relative w-full h-[4rem] sm:h-[15rem] text-base sm:text-2xl lg:text-3xl 2xl:text-4xl"
          style={{
            backgroundImage: `url(${buyBtn})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            className="absolute w-full h-full inset-0 z-10"
            src={buyBtn}
            alt="Buy/Sell button"
          />
          <p>Buy/Sell</p> <p>on</p>
          <div className="flex items-center justify-center gap-2">
            <img className="w-8" src={pacakelogo} alt="PacakeSwap logo" />
            <span className="">PancakeSwap</span>
          </div>
        </button> */}
        {/* </motion.div> */}
        {/* <SwapWidget
            tokenList={tokenList}
            hideConnectionUI={true}
            onConnectWalletClick={true}
            defaultInputTokenAddress={defaultInputAdd}
            defaultOutputTokenAddress={HXT}
            width="100%"
            theme={theme}
          /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default NewSwap;
