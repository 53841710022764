// store/slices/wallet.slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet(state, action) {
      const { wallet } = action.payload;
      // Only store essential properties to avoid serialization issues
      state.wallet = wallet
        ? {
            label: wallet.label,
            accounts: wallet.accounts.map((acc) => ({ address: acc.address })),
          }
        : null;
    },
    clearWallet(state) {
      state.wallet = null;
    },
  },
});

export const { setWallet, clearWallet } = walletSlice.actions;

export default walletSlice.reducer;
