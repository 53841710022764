const Footer = () => {
  return (
    <div className="bg-[#475C3A]   text-[#FFFFFF] flex flex-col justify-center items-center text-[16px] font-normal">
      <div className="sm:w-[79%]  w-[75%]  flex justify-center items-center  py-[3rem]">
        <p className=" text-white text-center font-lato text-[1.25rem] font-normal leading-[3rem]  tracking-[1.2px] font-Luckiest-Guy">
          $HOAXT has no official ties to Trump. It's just a playful nod to a
          meme that we all know and love. Like many meme coins, $HOAXT has no
          inherent value or promise of financial gain. There's no development
          team or roadmap – this token is purely for fun and entertainment
          purposes only.
        </p>
      </div>
      <div className="border-t border-t-white w-full text-center">
        <p className="text-white font-lato text-[16px] font-normal leading-[48px] tracking-[0.96px]">© 2024 by HXT. All rights reserved!</p>
      </div>
    </div>
  );
};

export default Footer;
