import { motion } from "framer-motion";
import React from "react";

const Tokennomics = () => {
    const hoaxonomics = "HOAXONOMICS".split("");
    const childVariants = {
        animate: { opacity: 1, y: 0 },
        transition: {
          duration: 0.5,
        },
      };
  return (
    <motion.div className="relative  text-center  mt-16 sm:mt-56 flex flex-col items-center justify-center">
      <div className="flex flex-col mx-7 sm:mx-20 items-center content-center justify-center  max-w-[55rem] gap-4 sm:gap-8 font-Lato font-bold text-white mb-10 sm:mb-20">
        <p className=" text-sm sm:text-3xl sm:leading-[2.7rem]">
          “We will soon connect with an established Layer2 Blockchain”
        </p>
        <p className=" max-w-[41rem] text-base sm:text-4xl sm:leading-[2.7rem]">
          It’s not as HOAX as you thought it to be :) Don’t miss the bus
        </p>
      </div>
      <div
        id="tokennomics"
        className="font-Lato text-center sm:max-w-[50.5rem] mx-auto text-white "
      >
        <p className="title mb-4 sm:mb-[3.8rem] text-5xl sm:text-9xl font-dk-scrawny">
          {hoaxonomics.map((hoax, i) => (
            <motion.span
              key={i}
              variants={childVariants}
              initial="hidden"
              whileInView="animate"
              viewport={{ once: true }}
            >
              {hoax}
            </motion.span>
          ))}
        </p>

        <p className="mb-3 sm:mb-12 text-base sm:text-[2rem] leading-10 font-semibold">
          Total Supply: 973M Tokens (HXT)
        </p>

        <p className="details tracking-wider text-xs sm:text-xl font-normal leading-5 mx-7 sm:mx-20 sm:leading-[3rem]">
          Zero Tax, abundant hoax. Total supply 973 Million. Power to rule the
          world. Join the real community that boasts, that it’s a Hoax but never
          lies, never deceives only to make you realize, $HXT is the power.
        </p>

        <p
          className="absolute -z-10 text-center font-normal bottom-0 left-[7%] translate-y-2/4 font-dk-scrawny text-[18vw]"
          style={{
            transform: "rotate(22.02deg)",
            color: "rgba(0, 0, 0, 0.05)",
          }}
        >
          Tokenomics
        </p>
      </div>
    </motion.div>
  );
};

export default Tokennomics;
