import React, { useReducer, useEffect } from "react";

import "./GiftBoxAnimation.css";
import greenBox from "../../assets/rewards/box1.svg";
import boxLidGreen from "../../assets/rewards/box-lid-Green.svg";

import Confetti from "../confetti/Confetti.jsx";

const init_state = {
  move: "move",
  jump: "",
  rotated: "",
  rotating: "",
};

export default function GiftBoxAnimation({
  styleProp,
  handleOpenBox,
  winnerPosition,
}) {
  const [state, setState] = useReducer(
    (state, new_state) => ({
      ...state,
      ...new_state,
    }),
    init_state
  );

  const { move, rotating, rotated, jump } = state;
  function animate() {
    let isDone = rotated === "rotated" ? true : false;

    if (!isDone) {
      setState({ rotating: "rotating" });
      setTimeout(() => {
        setState({ jump: "jump" });
      }, 300);
      setTimeout(() => {
        setState({ rotated: "rotated" });
      }, 1000);
    } else {
      setState(init_state);
    }
    let moving = move === "move" ? "" : "move";
    setState({ move: moving });
  }

  useEffect(() => {
    if (handleOpenBox) {
      animate();
    }
  }, [handleOpenBox]);

  return (
    <div
      className={`absolute z-50  size-[2rem] transition mx-auto box `}
      style={{ ...styleProp, ...winnerPosition }}
    >
      <Confetti open={jump === "jump"} />
      <div className="img-container   flex ">
        <button className="box flex " onClick={animate}>
          <img src={greenBox} alt="box" className="w-[2rem]" />
          <img
            className={`lid ${move} ${rotating} ${rotated} object-cover w-[2rem]`}
            src={boxLidGreen}
            alt="box-lid"
          />
        </button>
      </div>
    </div>
  );
}
