import { useState } from "react";
import chevronDown from "../../assets/icons/chevron-down.svg";
import { motion } from "framer-motion";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState();
  const accordionData = [
    {
      question: "What is Hoaxtrump?",
      answer:
        "HoaxTrump is a meme crypto token centered on fun, using President Trump as a meme. It's purely for entertainment, with no serious financial intent.",
    },
    {
      question: "How to buy Hoaxtrump?",
      answer:
        "Create a wallet. Buy some ETH/BNB. Go to uniswap. Swap your ETH/BNB for HXT",
    },
    {
      question: "What makes Hoaxtrump unique?",
      answer:
        "HoaxTrump stands out by focusing on fun and meme culture, not profits. It’s all about enjoying the humor with a playful community",
    },
    {
      question: "Where can I learn more?",
      answer:
        "You can learn more by visiting our website and connecting with the community on various platforms",
    },
  ];
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      {accordionData.map((item, index) => (
        <div
          key={index}
          className=" text-xs sm:text-base border-b-[1px] border-black"
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full flex justify-between items-center text-left"
          >
            <p className="flex gap-2 font-normal leading-10">
              <p className="text-[#4D7332]">Q{index + 1}.</p>
              {item.question}
            </p>
            <motion.div
              animate={{ rotate: activeIndex === index ? 180 : 0 }}
              transition={{ duration: 0.3 }}
              style={{ originY: 0.3 }}
            >
              <img src={chevronDown} alt="View" />
            </motion.div>
          </button>
          {activeIndex === index && (
            <div className="font-light text-left px-[1.3rem] pb-[1rem] leading-5 sm:leading-10">
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
