import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./HomeAwards.css";
import pinImage from "../../assets/rewards/pin.png";
import crossBtn from "../../assets/rewards/x (2).png";
import spinBtn from "../../assets/rewards/Group.svg";
import Snowfall from "react-snowfall";
import GiftBoxAnimation from "../Rewards/GiftBoxAnimation.jsx";
import { getSpinner } from "../../store/reducer/spinner.slice.js";
import { useDispatch, useSelector } from "react-redux";

const initialStyle = {
  left: 0,
  top: 0,
  visibility: "hidden",
  transform: `translate(0%, 0%) scale(0)`,
  opacity: 0,
};
const cardStyle = {
  animationName: "zoomIn",
  animationDuration: "1s",
  animationTimingFunction: "ease-in-out",
  animationDelay: "0s",
  animationIterationCount: "1",
  animationDirection: "normal",
  animationFillMode: "forwards",
  animationPlayState: "running",
};

const QaAwards = ({ setShowQaRewards, rewardsTxt }) => {
  const { spin } = useSelector((state) => state.spinner);
  const [isConnected, setIsConnected] = useState(false);
  // const { isConnected } = useWeb3ModalAccount();
  // const [rewardsTxt, setPrizeText] = useState("10 HXT");
  const [rotation, setRotation] = useState(0);
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const spinRef = useRef();
  const [winningState, setWinningState] = useState(true);
  const [winningBoxIndex, setWinningBoxIndex] = useState(null);
  const [winnerPosition, setWinnerPosition] = useState(initialStyle);
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const walletData = useSelector((state) => state.wallet);
  const address = walletData?.wallet?.accounts[0];

  const [typeWinning, setTypeWinning] = useState("10 HXT");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSpinner({ walletAddress: address?.address || null }));
  }, [dispatch, address]);

  const claimRewards = () => {
    setAnimationTrigger(true);
  };

  function closeSpinnerGame() {
    setShowQaRewards(false);
  }

  return (
    <div className={`relative w-full  flex flex-col`}>
      <div className=" w-[22.5rem]  md:w-[24.18rem] flex z-50 relative  justify-center items-center bg-[#4D7332] py-[17px] ">
        <img
          src={crossBtn}
          alt=""
          className="absolute sm:right-[12.5px] sm:top-[8px]  right-[10.5px] top-[10px] hover:cursor-pointer "
          onClick={closeSpinnerGame}
        />
        <img src={spinBtn} alt="" className="w-[70%] md:w-auto" />
      </div>
      <div
        className="w-full flex flex-col sm:flex-row items-center justify-around z-50 "
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className="flex flex-col items-center relative h-[20rem]"
        ></motion.div>

        <div
          onClick={claimRewards}
          className={`${
            winningState ? "opacity-1 visible" : "opacity-0 invisible"
          } fixed inset-0 bg-black/60 z-50 backdrop-blur-md w-full h-full transition-all duration-1000 flex justify-center`}
        >
          <img
            src={crossBtn}
            alt=""
            className="absolute right-[12.5px] top-[8px]  hover:cursor-pointer "
            onClick={closeSpinnerGame}
          />
          <Snowfall />
          {typeWinning === "gift" && (
            <GiftBoxAnimation
              classNameProp={`absolute z-40  size-[5rem]  transition`}
              styleProp={{
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                visibility: winnerPosition.visibility,
                opacity: winnerPosition.opacity,
                ...(winningState ? cardStyle : {}),
              }}
              handleOpenBox={animationTrigger}
              winnerPosition={winnerPosition}
              winningBoxIndex={winningBoxIndex}
              rewardsTxt={rewardsTxt}
              animationTrigger={animationTrigger}
            />
          )}

          {animationTrigger && typeWinning === "gift" && (
            <div className="absolute popUpContent h-[4rem] mt-[3.5rem] flex flex-col items-center justify-center py-[1rem] px-[5rem] text-center font-Lato font-bold font-2xl">
              <h1>You Won!!</h1>
              {/* <p className="pop-up-para">{prizes[winningBoxIndex]}</p> */}
              <p className="pop-up-para">{rewardsTxt}</p>
            </div>
          )}
          {typeWinning !== "gift" && (
            <div className="absolute top-[30%] -translate-y-1/2 popUpContent h-[4rem] mt-[3.5rem] flex flex-col items-center justify-center py-[3rem] px-[5rem] text-center font-Lato font-bold font-2xl">
              {rewardsTxt !== "OOPs" && <h1>You Won!!</h1>}
              <p className="pop-up-para">
                {rewardsTxt === "OOPS!" ? "Better Luck Next Time" : rewardsTxt}
              </p>
            </div>
          )}

          {!animationTrigger && typeWinning !== "gift" && (
            <motion.button className="absolute top-[78%] -translate-x-1/2 left-[49%] p-[3px]">
              <motion.button
                onClick={closeSpinnerGame}
                initial="rest"
                whileHover="hover"
                whileTap={{ scale: 0.9 }}
                animate="rest"
                variants={{
                  rest: { "--before-width": "0%", scale: 1 },
                  hover: { "--before-width": "100%", scale: 1.1 },
                }}
                className="px-3 sm:px-6 lg: py-1 sm:py-3 lg:py-[1rem] text-[#ffd81f] bg-[red] rounded-[2rem]"
                style={{
                  transition: "color .2s",
                  position: "relative",
                  overflow: "hidden",
                  zIndex: 1,
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                type="button"
              >
                Claim Reward
                <motion.div
                  className="button-overlay"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "var(--before-width)",
                    height: "100%",
                    backgroundColor: "#D08E00",
                    zIndex: -1,
                  }}
                  variants={{
                    rest: { width: "0%" },
                    hover: { width: "100%" },
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "easeInOut",
                  }}
                />
              </motion.button>
            </motion.button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QaAwards;
