import { motion } from "framer-motion";
const NavBar = () => {
  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")?.substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      window.history.pushState({}, document.title, `${targetId}`);
    }
  };
  return (
    <nav
      className="hidden sm:flex text-center items-center md:gap-[2rem] sm:gap-[1rem] lg:gap-[3rem] xl:gap-[4.5rem] max-w-[37.2rem] justify-around font-Lato text-xs lg:text-lg leading-5 z-20"
      // style={{ fontSize: "clamp(0.8rem, 50%, 2rem)" }}
    >
      <motion.a
        href="#about"
        onClick={handleScroll}
        whileHover={{ scale: 1.1 }} // Handle scaling directly here
        whileTap={{ scale: 0.9 }}
      >
        <p> About</p>
      </motion.a>
      <motion.a
        href="#roadmap"
        onClick={handleScroll}
        whileHover={{ scale: 1.1 }} // Handle scaling directly here
        whileTap={{ scale: 0.9 }}
      >
        <p>Roadmap</p>
      </motion.a>
      <motion.a
        href="#tokennomics"
        onClick={handleScroll}
        whileHover={{ scale: 1.1 }} // Handle scaling directly here
        whileTap={{ scale: 0.9 }}
      >
        <p>Tokennomics </p>
      </motion.a>
      <motion.a
        href="#faqs"
        onClick={handleScroll}
        whileHover={{ scale: 1.1 }} // Handle scaling directly here
        whileTap={{ scale: 0.9 }}
      >
        <p>Faqs </p>
      </motion.a>
    </nav>
  );
};

export default NavBar;
