import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./HomeAwards.css";
import pinImage from "../../assets/rewards/pin.png";
import crossBtn from "../../assets/rewards/x (2).png";
import spinBtn from "../../assets/rewards/Group.svg";
import HomeWheel from "../SVGs/HomeWheel.jsx";
import Snowfall from "react-snowfall";
import GiftBoxAnimation from "../Rewards/GiftBoxAnimation.jsx";
import { getSpinner } from "../../store/reducer/spinner.slice.js";
// import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useDispatch, useSelector } from "react-redux";
import { spinner } from "../../services/spinnerService.js";
import { convertTimeToHours } from "../../utils/convertTimeToHours.js";
import { ethers } from "ethers";
import { useConnectWallet } from "@web3-onboard/react";

const initialStyle = {
  left: 0,
  top: 0,
  visibility: "hidden",
  transform: `translate(0%, 0%) scale(0)`,
  opacity: 0,
};
const cardStyle = {
  animationName: "zoomIn",
  animationDuration: "1s",
  animationTimingFunction: "ease-in-out",
  animationDelay: "0s",
  animationIterationCount: "1",
  animationDirection: "normal",
  animationFillMode: "forwards",
  animationPlayState: "running",
};

const HomeAwards = ({ setShowSpinnerGame }) => {
  const { spin } = useSelector((state) => state.spinner);
  const [isConnected, setIsConnected] = useState(false);
  // const { isConnected } = useWeb3ModalAccount();
  const [prizeText, setPrizeText] = useState("");
  const [rotation, setRotation] = useState(0);
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const spinRef = useRef();
  const [winningState, setWinningState] = useState(null);
  const [winningBoxIndex, setWinningBoxIndex] = useState(null);
  const [winnerPosition, setWinnerPosition] = useState(initialStyle);
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const walletData = useSelector((state) => state.wallet);
  const address = walletData?.wallet?.accounts[0];

  const [typeWinning, setTypeWinning] = useState("");
  const [spinAlert, setSpinAlert] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const dispatch = useDispatch();
  // const { open } = useWeb3Modal();
  const removeClass = (prizeIndex) => {
    setWheelSpinning(false);
  };

  // const checkConnection = async () => {
  //   try {
  //     if (!window.ethereum) {
  //       console.warn("No crypto wallet found. Please install it.");
  //       return;
  //     }

  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const accounts = await provider.listAccounts();
  //     if (accounts.length > 0) {
  //       setIsConnected(true);
  //       setAddress(accounts[0]);
  //     } else {
  //       setIsConnected(false);
  //       setAddress(null);
  //     }
  //   } catch (err) {
  //     console.error("Error checking connection:", err);
  //   }
  // };

  // const connectWallet = async () => {
  //   try {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     await provider.send("eth_requestAccounts", []);
  //     const signer = provider.getSigner();
  //     const userAddress = await signer.getAddress();
  //     setIsConnected(true);
  //     setAddress(userAddress);
  //   } catch (err) {
  //     console.error("Wallet connection failed:", err);
  //   }
  // };

  const checkConnection = async () => {
    if (address) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  };

  const connectWallet = async () => {
    await connect();
    setIsConnected(true);
  };

  useEffect(() => {
    checkConnection();
  }, [address]);

  useEffect(() => {
    let intervalId;
    if (spin?.nextSpinTime) {
      intervalId = setInterval(() => {
        const calculatedTime = convertTimeToHours(spin.nextSpinTime);
        setRemainingTime(calculatedTime);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [spin?.nextSpinTime]);

  const spinWheel = async () => {
    if (wheelSpinning) return;
    if (isConnected) {
      try {
        const spinData = await spinner(address?.address);
        setWheelSpinning(true);

        const prizeIndex = spinData.reward.id;

        const spins = 3600 + (prizeIndex - 1) * 60;
        const newRotation = rotation + spins;
        setRotation(newRotation);
        setTimeout(() => {
          const finalRotation = newRotation % 360;
          const winningIndex = Math.floor(finalRotation / 60);

          setWinningBoxIndex(winningIndex);

          removeClass(winningIndex);
          setWheelSpinning(false);
          if (spinRef.current) {
            const { x: xPosition, y: yPosition } =
              spinRef.current.getBoundingClientRect();
            setWinnerPosition({
              left: xPosition,
              top: yPosition + 120,
              visibility: "visible",
              opacity: 1,
              transform: "translate(-50%, -50%)",
            });
            setTimeout(() => {
              setWinningState(true);
            }, 10);
          }
        }, 5000);
      } catch (e) {
        const spinTime = e?.response?.data;

        setSpinAlert(spinTime?.nextSpinTime);
      }
    } else {
      setSpinAlert("Connect your wallet first");
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === " " && !wheelSpinning) {
        spinWheel();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [wheelSpinning]);

  useEffect(() => {
    dispatch(getSpinner({ walletAddress: address?.address || null }));
  }, [dispatch, address]);

  const claimRewards = () => {
    setAnimationTrigger(true);
  };

  const urlSetQueryParams = () => {
    const url = new URLSearchParams(window.location.search);
    url.set("spinner_Game", true);
    window.history.pushState({}, "", `${window.location.pathname}`);
  };
  function closeSpinnerGame() {
    urlSetQueryParams();
    setShowSpinnerGame(false);
  }

  return (
    <div className={`relative w-full  flex flex-col`}>
      <div className=" w-[22.5rem]  md:w-[24.18rem] flex z-50 relative  justify-center items-center bg-[#4D7332] py-[17px] ">
        <img
          src={crossBtn}
          alt=""
          className="absolute sm:right-[12.5px] sm:top-[8px]  right-[10.5px] top-[10px] hover:cursor-pointer "
          onClick={closeSpinnerGame}
        />
        <img src={spinBtn} alt="" className="w-[70%] md:w-auto" />
      </div>
      <div
        className="w-full flex flex-col sm:flex-row items-center justify-around z-50 "
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className="flex flex-col items-center relative"
        >
          {spinAlert && !isConnected && (
            <p className="font-Montserrat font-semibold text-base text-[#DE2C2C] pt-3">
              {spinAlert}{" "}
              <span
                onClick={connectWallet}
                className="underline cursor-pointer"
              >
                Connect?
              </span>
            </p>
          )}
          {remainingTime && (
            <p className="font-Montserrat font-semibold text-base text-[#DE2C2C] pt-3">
              <span>Free spin in: </span>
              <span>{remainingTime}</span>
            </p>
          )}
          <img
            className={`h-[4rem] ${wheelSpinning ? "pendulum" : ""}`}
            src={pinImage}
            alt="Pin"
            ref={spinRef}
          />

          <div className="relative z-10 md:mx-[0rem] mx-[1rem]">
            <HomeWheel
              classProp={`w-full  bg-red-400 sm:size-[31rem] transition-transform duration-[5s] ease-out `}
              styleProp={{ transform: `rotate(${rotation}deg)` }}
              wheelAngle={rotation}
              winningBoxIndex={winningBoxIndex}
              setImagePath={() => {}}
              prizeText={prizeText}
              setPrizeText={setPrizeText}
              setTypeWinning={setTypeWinning}
            />
            <button
              className="absolute text-lg font-bold top-[47%]  left-1/2  -translate-x-1/2 -translate-y-1/2 size-16  sm:size-[4rem]  flex items-center justify-center bg-[black] text-white rounded-full border"
              type="button"
              onClick={spinWheel}
            >
              Spin
            </button>
          </div>
        </motion.div>

        <div
          onClick={claimRewards}
          className={`${
            winningState ? "opacity-1 visible" : "opacity-0 invisible"
          } fixed inset-0 bg-black/60 z-50 backdrop-blur-md w-full h-full transition-all duration-1000 flex justify-center`}
        >
          <img
            src={crossBtn}
            alt=""
            className="absolute right-[12.5px] top-[8px]  hover:cursor-pointer "
            onClick={closeSpinnerGame}
          />
          <Snowfall />
          {typeWinning === "gift" && (
            <GiftBoxAnimation
              classNameProp={`absolute z-40  size-[5rem]  transition`}
              styleProp={{
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                visibility: winnerPosition.visibility,
                opacity: winnerPosition.opacity,
                ...(winningState ? cardStyle : {}),
              }}
              handleOpenBox={animationTrigger}
              winnerPosition={winnerPosition}
              winningBoxIndex={winningBoxIndex}
              prizeText={prizeText}
              animationTrigger={animationTrigger}
            />
          )}

          {animationTrigger && typeWinning === "gift" && (
            <div className="absolute popUpContent h-[4rem] mt-[3.5rem] flex flex-col items-center justify-center py-[1rem] px-[5rem] text-center font-Lato font-bold font-2xl">
              <h1>You Won!!</h1>
              {/* <p className="pop-up-para">{prizes[winningBoxIndex]}</p> */}
              <p className="pop-up-para">{prizeText}</p>
            </div>
          )}
          {typeWinning !== "gift" && (
            <div className="absolute top-[40%] -translate-y-1/2 popUpContent h-[4rem] mt-[3.5rem] flex flex-col items-center justify-center py-[3rem] px-[5rem] text-center font-Lato font-bold font-2xl">
              {prizeText !== "OOPs" && <h1>You Won!!</h1>}
              {/* <p className="pop-up-para">{prizes[winningBoxIndex]}</p> */}
              <p className="pop-up-para">
                {prizeText === "OOPS!" ? "Better Luck Next Time" : prizeText}
              </p>
            </div>
          )}

          {animationTrigger && typeWinning === "gift" ? (
            <motion.button
              onClick={() => {}}
              className="absolute top-[78%] -translate-x-1/2 left-[49%] p-[3px]"
            >
              <motion.button
                onClick={closeSpinnerGame}
                initial="rest"
                whileHover="hover"
                whileTap={{ scale: 0.9 }}
                animate="rest"
                variants={{
                  rest: { "--before-width": "0%", scale: 1 },
                  hover: { "--before-width": "100%", scale: 1.1 },
                }}
                className="px-3 sm:px-6 lg: py-1 sm:py-3 lg:py-[1rem] text-[#ffd81f] bg-[red] rounded-[2rem]"
                style={{
                  transition: "color .2s",
                  position: "relative",
                  overflow: "hidden",
                  zIndex: 1,
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                type="button"
              >
                Claim Reward
                <motion.div
                  className="button-overlay"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "var(--before-width)",
                    height: "100%",
                    backgroundColor: "#D08E00",
                    zIndex: -1,
                  }}
                  variants={{
                    rest: { width: "0%" },
                    hover: { width: "100%" },
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "easeInOut",
                  }}
                />
              </motion.button>
            </motion.button>
          ) : (
            <div
              className={`absolute top-[78%] -translate-x-1/2 left-[49%] w-full text-center uppercase p-[3px] text-white text-[2rem] font-bold ${
                !animationTrigger && typeWinning !== "gift" && "hidden"
              }`}
            >
              Click To Reveal
            </div>
          )}

          {!animationTrigger && typeWinning !== "gift" && (
            <motion.button className="absolute top-[78%] -translate-x-1/2 left-[49%] p-[3px]">
              {/* <motion.button className="absolute top-[50%] -translate-x-1/2 left-[49%] p-[3px]"> */}
              <motion.button
                onClick={closeSpinnerGame}
                initial="rest"
                whileHover="hover"
                whileTap={{ scale: 0.9 }}
                animate="rest"
                variants={{
                  rest: { "--before-width": "0%", scale: 1 },
                  hover: { "--before-width": "100%", scale: 1.1 },
                }}
                className="px-3 sm:px-6 lg: py-1 sm:py-3 lg:py-[1rem] text-[#ffd81f] bg-[red] rounded-[2rem]"
                style={{
                  transition: "color .2s",
                  position: "relative",
                  overflow: "hidden",
                  zIndex: 1,
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                type="button"
              >
                Claim Reward
                <motion.div
                  className="button-overlay"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "var(--before-width)",
                    height: "100%",
                    backgroundColor: "#D08E00",
                    zIndex: -1,
                  }}
                  variants={{
                    rest: { width: "0%" },
                    hover: { width: "100%" },
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "easeInOut",
                  }}
                />
              </motion.button>
            </motion.button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeAwards;
