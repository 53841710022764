import React from "react";
import { motion } from "framer-motion";

const VotingBtnCircle = ({ color, hoverColor }) => {
  return (
    <>
      <motion.svg
         className="w-[1.5rem]  sm:w-[5.3vw]"
        viewBox="0 0 81 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1747_785)">
          <motion.path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.1738 42.6764C64.1738 43.7604 64.1738 44.8193 64.1738 45.9033C64.1738 49.3066 64.6023 52.7603 61.6528 55.5838C58.073 58.9368 54.3167 61.9115 49.2495 62.1384C43.9355 62.5251 38.599 62.483 33.2917 62.0124C26.8835 61.107 20.7734 58.7251 15.4432 55.0544C13.3586 53.3996 11.7853 51.1882 10.9054 48.6764C9.12744 44.4915 8.32567 39.9568 8.56089 35.416C8.56408 33.4541 8.98892 31.5159 9.80663 29.7326C10.6243 27.9493 11.8158 26.3626 13.3003 25.08C19.8892 18.8767 27.5859 13.968 35.9892 10.6095C38.3589 9.67675 39.8715 6.14738 42.9219 7.55913C44.9724 8.76478 46.8831 10.1936 48.6193 11.8196C49.3832 12.5602 50.229 13.2115 51.1403 13.7607C58.2999 16.9372 60.8209 23.5422 63.115 30.0463C64.0993 34.178 64.6318 38.4044 64.7032 42.6512L64.1738 42.6764Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.5942 70.5082C31.6279 72.1216 23.6112 68.441 15.4936 64.9116C5.35928 60.5755 1.90554 51.979 0.518997 42.0463C0.292007 39.3704 0.292007 36.6802 0.518997 34.0043C0.420222 31.0562 1.03847 28.1281 2.32066 25.4715C3.60285 22.8149 5.51066 20.5092 7.88027 18.7524C11.3766 15.5878 15.1572 12.7523 19.1743 10.2819C20.5949 9.52946 21.768 8.38267 22.5524 6.97941C23.4928 5.21506 24.9061 3.74749 26.6337 2.74119C28.3614 1.73488 30.3351 1.22961 32.3338 1.28199C34.4766 1.28199 36.6195 1.28199 38.7371 1.00469C41.1136 0.652002 43.5399 0.870492 45.8152 1.64207C48.0905 2.41364 50.1492 3.71611 51.821 5.44162C54.1333 7.67164 56.7677 9.54127 59.636 10.9878C62.2061 12.4005 64.302 14.5403 65.6612 17.139C72.6443 29.5674 72.6947 29.5422 72.6947 43.7101C72.6063 44.7839 72.6063 45.8632 72.6947 46.937C74.3334 55.7856 69.0393 61.0797 62.8881 65.6175C58.7597 69.1425 53.4352 70.9474 48.0143 70.6594C45.695 70.3065 43.174 70.5082 39.5942 70.5082ZM64.1738 42.7774H64.7032C64.6319 38.5306 64.0993 34.3042 63.115 30.1725C60.8209 23.5423 58.2999 16.9373 51.1403 13.7104C50.229 13.1612 49.3833 12.5099 48.6193 11.7693C46.8806 10.1609 44.9699 8.74907 42.9219 7.55924C39.8715 6.19791 38.3589 9.67687 35.9892 10.6096C27.586 13.9681 19.8893 18.8769 13.3004 25.0801C11.8159 26.3627 10.6244 27.9494 9.80668 29.7327C8.98896 31.516 8.56412 33.4543 8.56093 35.4161C8.32571 39.9569 9.12748 44.4916 10.9054 48.6765C11.7854 51.1884 13.3587 53.3997 15.4432 55.0546C20.7141 58.6964 26.7521 61.0771 33.0901 62.0125C38.3973 62.4831 43.7339 62.5252 49.0479 62.1385C54.0899 61.9116 57.8714 58.9369 61.4511 55.584C64.4763 52.7605 63.9721 49.3067 63.9721 45.9034C64.199 44.8194 64.1738 43.7606 64.1738 42.6765V42.7774Z"
            fill="black"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1747_785"
            x="0.348755"
            y="0.84082"
            width="80.6473"
            height="82.0581"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="8" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1747_785"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1747_785"
              result="shape"
            />
          </filter>
        </defs>
      </motion.svg>
    </>
  );
};

export default VotingBtnCircle;
