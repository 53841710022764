import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSpinner } from "../../services/spinnerService";

export const getSpinner = createAsyncThunk(
  "user/spinner",
  async ({ walletAddress }, { rejectWithValue }) => {
    try {
      const response = await fetchSpinner(walletAddress);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const spinnerSlice = createSlice({
  name: "spin",
  initialState: {
    spin: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpinner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSpinner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spin = action.payload;
      })
      .addCase(getSpinner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch spinner data";
      });
  },
});

export default spinnerSlice.reducer;
