import api from "./api";

export const users = async (walletAddress) => {
  try {
    const response = await api.post("/users", {
      wallet_address: walletAddress || null,
    });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

export const fetchQuiz = async (walletAddress) => {
  try {
    const response = await api.get("/quiz/question", {
      params: { wallet_address: walletAddress },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const quizAns = async (answerdata) => {
  try {
    const response = await api.post("/quiz/answer", {
      wallet_address: answerdata.walletAddress,
      que_id: answerdata.que_id,
      ans_id: answerdata.ans_id,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
