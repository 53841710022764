import { motion } from "framer-motion";
import ReactDOM from "react-dom";
import "./Sidebar.css";

const sidebarVariants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0px at 40px 40px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const ulVariants = {
  open: {
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const liVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 60,
      damping: 12,
      duration: 0.5,
      ease: "easeOut",
    },
  },
  closed: {
    opacity: 0,
    y: 50,
    transition: {
      type: "tween",
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

function Sidebar({ isOpen, setIsOpen }) {
  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")?.substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      window.history.pushState({}, document.title, `#${targetId}`);
    }
    setIsOpen(false); 
  };

  const sidebarContent = (
    <>
      <motion.div
        className="fixed top-0 left-0 h-full w-64 bg-[#475C3A] text-white p-6 shadow-lg z-40"
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={sidebarVariants}
      >
        <motion.ul
          className="space-y-4 mt-[30px] headerList"
          variants={ulVariants}
        >
          <motion.li variants={liVariants}>
            <a
              href="#about"
              onClick={handleScroll}
              className="hover:text-gray-300"
            >
              About
            </a>
          </motion.li>
          <motion.li variants={liVariants}>
            <a
              href="#roadmap"
              onClick={handleScroll}
              className="hover:text-gray-300"
            >
              Roadmap
            </a>
          </motion.li>
          <motion.li variants={liVariants}>
            <a
              href="#tokennomics"
              onClick={handleScroll}
              className="hover:text-gray-300"
            >
              Tokennomics
            </a>
          </motion.li>
          <motion.li variants={liVariants}>
            <a
              href="#faqs"
              onClick={handleScroll}
              className="hover:text-gray-300"
            >
              FAQs
            </a>
          </motion.li>
        </motion.ul>
      </motion.div>

     
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );

  return ReactDOM.createPortal(sidebarContent, document.body);
}

export default Sidebar;
