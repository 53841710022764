export function convertTimeToHours(isoTime) {
  // Create a Date object from the ISO 8601 time string and the current time
  const targetDate = new Date(isoTime);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDiff = targetDate - currentDate;

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  // Format the remaining time as "HH:MM:SS"
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return formattedTime;
  }