import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import "./App.css";
import NewAbout from "./components/About/NewAbout";
import { useEffect, useState } from "react";
import { useConnectWallet } from "@web3-onboard/react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { users } from "./services/userService";

function App() {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const walletData = useSelector((state) => state.wallet);
  const address = walletData?.wallet?.accounts[0];
  const [provider, setProvider] = useState();

  useEffect(() => {
    if (wallet?.provider) {
      // if using ethers v6 this is:
      // ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')
      setProvider(new ethers.providers.Web3Provider(wallet.provider));
    } else {
      // Reset the provider back to 'undefined' such that the
      // connect wallet option will reappear in the uniswap modal
      setProvider(undefined);
    }
  }, [wallet]);

  useEffect(() => {
    const createUser = async (address) => {
      try {
        const data = await users(address?.address);
      } catch (error) {
        console.error("failed:", error);
      }
    };
    if (address) {
      createUser(address);
    }
  }, [address]);

  const connectWallet = () => {
    connect();
  };

  return (
    <div className="relative">
      <Header connectWallet={connectWallet} />
      <Home />
      <NewAbout />
    </div>
  );
}

export default App;
