import Lottie from "lottie-react";
import { motion, AnimatePresence } from "framer-motion";
import heroWalpaper from "../../assets/gif/hero-wallpaper.gif";
import heroImg from "../../assets/images/hero.svg";
import star from "../../assets/animations/star.json";
import { useState, useEffect } from "react";
import "./Home.css";
import RewardsModal from "../../components/Rewards/RewardsModal";
import HomeAwards from "../../components/HomeAwards/HomeAwards";
import Swap from "../../components/Swap/NewSwap";
// import Swap from "../../components/Swap/Swap";
const Home = () => {
  const [showSpinnerGame, setShowSpinnerGame] = useState(false);
  const [showMobileSpinnerGame, setShowMobileSpinnerGame] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const hoaxTrumpParagraph =
    `It’s a Hoax, Trump of the Hoax, no bigger hoax than this in memecoin world. You got to follow it. You got to grab the $HXT to make it real.`.split(
      " "
    );

  const urlSetQueryParams = () => {
    const url = new URLSearchParams(window.location.search);
    url.set("spinner_Game", true);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${url.toString()}`
    );
  };
  const urlGetQueryParams = () => {
    const url = new URLSearchParams(window.location.search);
    const isSpinOpen = url.get("spinner_Game");

    if (isSpinOpen) {
      if (isMobile) {
        setShowMobileSpinnerGame(true);
      } else {
        setShowSpinnerGame(true);
      }
    }
  };

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    urlGetQueryParams();

    if (showMobileSpinnerGame) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }

    return () => {
      html.classList.remove("lock-scroll");
    };
  }, [showMobileSpinnerGame, showSpinnerGame]);

  const textVariants = {
    initial: { opacity: 1, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 1, y: 20 },
    transition: { duration: 0.5 },
  };

  const buttonVariants = {
    initial: { opacity: 1, scale: 1 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 1, scale: 1 },
    transition: { duration: 0.5 },
  };

  const slideAnimation = {
    initial: { x: "100%", opacity: 0 },
    animate: { x: "-50%", opacity: 1 },
    exit: { x: "100%", opacity: 0 },
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const rewardsHandller = () => {
    if (!isMobile) {
      const newShowSpinnerGame = !showSpinnerGame;
      setShowSpinnerGame(newShowSpinnerGame);

      if (newShowSpinnerGame) {
        urlSetQueryParams();
      } else {
        window.history.replaceState({}, "", window.location.pathname);
      }
    } else {
      const newShowMobileSpinnerGame = !showMobileSpinnerGame;
      setShowMobileSpinnerGame(newShowMobileSpinnerGame);

      if (newShowMobileSpinnerGame) {
        urlSetQueryParams();
      } else {
        window.history.replaceState({}, "", window.location.pathname);
      }
    }
  };

  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")?.substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      window.history.pushState({}, document.title, `${targetId}`);
    }
  };

  return (
    <>
      <div className="relative">
        <div
          className="absolute  min-h-screen h-[1640px] 2xl:h-[1900px] w-full"
          style={{
            backgroundImage: `url(${heroWalpaper})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            opacity: 0.5,
          }}
        ></div>
        <div className="relative">
          <motion.div className=" flex flex-col-reverse sm:flex-row  justify-between items-center sm:items-start 2xl:items-center text-center sm:text-left pt-16 sm:pt-[14rem] sm:mx-16 lg:mx-[7.5rem] sm:mb-36">
            <motion.div
              className={`max-w-[18rem]  ${
                showSpinnerGame ? "lg:max-w-[27.5rem]" : "lg:max-w-[37.5rem]"
              }  `}
            >
              <h1 className=" font-hey-august  text-[2.5rem]  lg:text-[4.5rem] 2xl:text-[6rem]">
                HOAXTRUMP
              </h1>
              <motion.p
                className="font-Lato text-xs lg:text-2xl 2xl:text-3xl leading-[1.5rem] lg:leading-[3rem] text-[#4D7332] mb-3 sm:mb-10"
                variants={textVariants}
                initial="initial"
                animate={showSpinnerGame ? "animate" : "exit"}
                exit="exit"
                transition={textVariants.transition}
              >
                {hoaxTrumpParagraph.map((HoaxtParagraph, i) => {
                  const baseDelay = hoaxTrumpParagraph.length * 0.05;

                  if (HoaxtParagraph === "$HXT") {
                    return (
                      <span key={i} style={{ display: "inline-block" }}>
                        {HoaxtParagraph.split("").map((char, index) => (
                          <motion.span
                            key={index}
                            style={{ display: "inline-block" }}
                            initial={{
                              opacity: 0,
                              rotateX: 0,
                              color: "#000000",
                            }}
                            animate={{
                              opacity: 1,
                              rotateX: 360,
                              color: "#4D7332",
                            }}
                            transition={{
                              duration: 0.5,
                              delay: baseDelay + index * 0.1,
                              ease: "easeInOut",
                            }}
                          >
                            {char}
                          </motion.span>
                        ))}
                        &nbsp;
                      </span>
                    );
                  } else {
                    return (
                      <motion.span
                        key={i}
                        style={{ display: "inline-block" }}
                        initial={{ opacity: 0, rotateX: 0 }}
                        animate={{ opacity: 1, rotateX: 0 }}
                        transition={{
                          duration: 0.25,
                          delay: i * 0.05,
                          ease: "easeInOut",
                        }}
                      >
                        {HoaxtParagraph}&nbsp;
                      </motion.span>
                    );
                  }
                })}
              </motion.p>

              <motion.div
                className=" flex justify-center sm:justify-start gap-1 sm:gap-[1rem] font-Lato text-[0.5rem] sm:text-xs lg:text-lg lg:leading-5   mt-[2rem] md:mt-0"
                variants={buttonVariants}
                initial="initial"
                animate={showSpinnerGame ? "animate" : "exit"}
                exit="exit"
                transition={buttonVariants.transition}
              >
                <motion.div
                  initial={{ opacity: 0, y: 50, scale: 0.6 }}
                  whileInView={{ opacity: 1, y: 0, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.1,
                    delay: 2,
                    type: "spring",
                    stiffness: 300,
                    damping: 10,
                  }}
                >
                  <motion.a
                    href="https://pancakeswap.finance/?inputCurrency=BNB&outputCurrency=0x6Fe4239f396fb169cd38d1fa8cDc174CF9824D5a"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-block",
                      textDecoration: "none",
                      overflow: "hidden",
                    }}
                    initial="rest"
                    whileHover="hover"
                    whileTap={{ scale: 0.9 }}
                    animate="rest"
                    variants={{
                      rest: { "--before-width": "0%", scale: 1 },
                      hover: { "--before-width": "100%", scale: 1.1 },
                    }}
                  >
                    <motion.div
                      className="px-3 sm:px-6 lg:px-12 py-1 sm:py-3 lg:py-[1.1rem] text-white bg-[#4D7332] rounded-[2rem]"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        zIndex: 1,
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                    >
                      Buy $HXT
                      <motion.div
                        className="button-overlay"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "var(--before-width)",
                          height: "100%",
                          backgroundColor: "#2E451E",
                          zIndex: -1,
                        }}
                        variants={{
                          rest: { width: "0%" },
                          hover: { width: "100%" },
                        }}
                        transition={{
                          duration: 0.3,
                          ease: "easeInOut",
                        }}
                      />
                    </motion.div>
                  </motion.a>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 50, scale: 0.6 }}
                  whileInView={{ opacity: 1, y: 0, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.1,
                    delay: 2.3,
                    type: "spring",
                    stiffness: 300,
                    damping: 10,
                  }}
                >
                  <motion.button
                    initial="rest"
                    whileHover="hover"
                    whileTap={{ scale: 0.9 }}
                    animate="rest"
                    variants={{
                      rest: { "--before-width": "0%", scale: 1 }, // Define scale in the rest state
                      hover: { "--before-width": "100%", scale: 1.1 }, // Combine scale and background animation on hover
                    }}
                    className={`${
                      showSpinnerGame ? "lg:px-8" : "lg:px-12"
                    } px-3 sm:px-6 lg:px-12 py-1 sm:py-3 lg:py-[1.1rem] text-[#4D7332] rounded-[2rem] border-[1px] border-[#4D7332] hover:text-white`}
                    style={{
                      transition: "color .2s",
                      position: "relative",
                      overflow: "hidden",
                      zIndex: 1,
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }} // Spring transition for scale
                    type="button"
                    onClick={rewardsHandller}
                  >
                    EARN REWARDS
                    <motion.div
                      className="button-overlay"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "var(--before-width)",
                        height: "100%",
                        backgroundColor: "#4D7332",
                        zIndex: -1,
                      }}
                      variants={{
                        rest: { width: "0%" },
                        hover: { width: "100%" },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: "easeInOut",
                      }}
                    />
                  </motion.button>
                </motion.div>
              </motion.div>
            </motion.div>
            <div className="relative max-w-[13.5rem]  lg:max-w-[25rem] 2xl:max-w-[34rem]">
              <div
                className="absolute -left-16 sm:-left-20  sm:-inset-40 w-24 sm:w-60"
                style={{ transform: "rotate(-10.17deg)" }}
              >
                <Lottie animationData={star} loop={true} />
              </div>
              {/* <div
                className="absolute -right-20  -bottom-10 sm:-right-[4rem] lg:-bottom-[12rem] md:-bottom-[12rem] w-20 sm:w-48 sm:-bottom-[10rem]"
                style={{ transform: "rotate(-23.69deg)" }}
              >
                <Lottie animationData={star} loop={true} />
              </div> */}
              {/* <div
                className="hidden sm:block absolute  -bottom-72 sm:-left-40 sm:-bottom-[10rem] w-28"
                style={{ transform: "rotate(37.17deg)" }}
              >
                <Lottie animationData={star} loop={true} />
              </div> */}
              <AnimatePresence>
                {showSpinnerGame ? (
                  <motion.img
                    className={`object-contain   mb-[6rem]  ${
                      showSpinnerGame ? "w-[25rem]" : "w-[20rem]"
                    } sm:w-[25rem]`}
                    src={heroImg}
                    alt="Hero Image"
                    variants={slideAnimation}
                    initial="initial"
                    animate={showSpinnerGame ? "animate" : "exit"}
                    exit="exit"
                  />
                ) : (
                  <img
                    className=" object-contain w-48 sm:w-full 2xl:w-[34rem]"
                    src={heroImg}
                    alt="Hero"
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {showSpinnerGame && (
                  <motion.div
                    className="z-30 flex  justify-center items-center gradient-border-yellow me-[2rem] mb-[2rem]  fixed bottom-0 right-0 w-[24.18rem] "
                    initial={{ y: "100%", opacity: 0 }}
                    animate={{ y: "0%", opacity: 1 }}
                    exit={{ y: "100%", opacity: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 70,
                      damping: 15,
                      duration: 0.6,
                    }}
                    style={{ position: "fixed" }}
                  >
                    <HomeAwards setShowSpinnerGame={setShowSpinnerGame} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
          <Swap />
          {/* <About/> */}
          {/* <NewAbout/> */}
        </div>
      </div>
      {/* {openRewards && <Rewards rewardsHandller={rewardsHandller} />} */}
      {showMobileSpinnerGame && isMobile && (
        <RewardsModal setShowSpinnerGame={setShowMobileSpinnerGame} />
      )}
    </>
  );
};

export default Home;
